import React from 'react'
import { Link } from 'gatsby'

import { ChevronRightIcon } from '@heroicons/react/outline'

import { ProductFeatureRow } from '@/components/tailwind/product-feature-row'
import { CartSidebar } from '@/components/tailwind/cart-sidebar'
import { Header } from '@/components/tailwind/header'
import { Footer } from '@/components/tailwind/footer'

import { useStaticImages } from '@/hooks/use-static-image'
import { useSiteConfig } from '@/hooks/use-site-config'

const IndexPage = () => {
  const siteConfig = useSiteConfig()

  const [
    Banner,
    MobileBanner,
    Promo1,
    Promo2,
    Promo3,
    Promo4,
  ] = useStaticImages(
    'air-wallet-banner.png',
    'air-wallet-banner-mobile.png',
    'air-wallet-promo-1.jpg',
    'air-wallet-promo-2.jpg',
    'air-wallet-promo-3.jpg',
    'air-wallet-promo-4.jpg',
  )

  return (
    <>
      <main className={`bg-concrete-500 text-mine-shaft`}>
        <header>
          <Header
            float={true}
            dark={true}
            background={`bg-transparent`}
            {...siteConfig}
          />
          <CartSidebar />

          {/* Hero section */}
          <section className='relative'>
            <div className='absolute bottom h-full mt-auto flex flex-col w-full p-5 sm:p-6 lg:p-8 z-10'>
              <div className='container mx-auto h-full flex flex-col justify-end'>
                <h1 className='text-left text-4xl font-extrabold tracking-normal pb-4 sm:text-5xl lg:text-6xl mt-auto'>
                  <span className='text-white uppercase'>Made for AirTag</span>
                </h1>
                <h2 className='text-left font-light text-3xl tracking-wide sm:text-2xl lg:text-3xl'>
                  <span className='text-white uppercase'>
                    Anovus Smart Wallet
                  </span>
                </h2>
                <div className='mt-10 w-full sm:max-w-none sm:flex sm:justify-center'>
                  <div className='space-y-4 sm:space-y-0 mr-auto sm:inline-grid sm:grid-cols-2 sm:gap-5'>
                    <Link
                      className='flex items-center justify-center px-4 py-3 border border-white text-base  uppercase rounded-sm text-white font-medium shadow-sm hover:bg-indigo-50 sm:px-8'
                      to='/products/smart-wallet'
                    >
                      Discover Smart Wallet
                    </Link>

                    <Link
                      className='flex items-center justify-center px-4 py-3 border border-transparent text-base uppercase rounded-sm font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 transition duration-150 ease-in-out sm:px-8'
                      to='/collections/accessories'
                    >
                      Explore Accessories
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='mx-auto min-h-fit	'>
              <div className='relative shadow-xl'>
                <div
                  className='relative inset-0'
                  style={{
                    minHeight: '23vh',
                  }}
                >
                  <div
                    className={`hidden md:flex overflow sm:w-auto sm:h-auto lg:w-screen`}
                  >
                    <Banner
                      loading={`eager`}
                      style={{ maxHeight: '100vh', width: 'auto' }}
                      className={`hidden md:flex overflow sm:w-auto sm:h-auto lg:w-screen`}
                      relativePath={`air-wallet-banner.png`}
                    />
                  </div>
                  <div className={`flex sm:hidden`}>
                    <MobileBanner
                      loading={`eager`}
                      style={{ maxHeight: 639, width: '100vw' }}
                      relativePath={`air-wallet-banner-mobile.png`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>

        <section className='container mx-auto px-5 md:px-0'>
          <div className='grid grid-cols-1 gap-x-4 my-4 md:grid-cols-2 md:gap-x-16 md:my-16'>
            <div>
              <div className={`align-middle`}>
                <Promo4 loading={`lazy`} />
              </div>
            </div>

            <div className='my-auto'>
              <h2 className='mt-5 text-base font-semibold uppercase tracking-wider'>
                Award winning design
              </h2>
              <p className='mt-0 text-2xl font-extrabold uppercase tracking-tight sm:text-4xl sm:mt-2'>
                The First Smart Wallet For AirTag
              </p>
              <p className='mt-3'>Crafted from premium, full-grain leather.</p>
              <p className='mt-3'>
                Designed with an integrated cardholder that fans out your cards
                and protects them against skimming.
              </p>
              <p className='mt-3'>
                Paired with Apple AirTag, you'll never need to worry about
                losing it.
              </p>
              <div className='mt-8'>
                <div className='inline-flex rounded-md shadow'>
                  <Link
                    className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base uppercase font-medium rounded-sm text-white bg-gray-900 hover:bg-gray-800 transition-colors'
                    to='/products/smart-wallet'
                  >
                    Shop Now
                    <ChevronRightIcon
                      className='-mr-1 ml-3 h-5 w-5 text-white'
                      aria-hidden='true'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          {[
            {
              title: 'Genuine Leather',
              subTitle: 'Craftsmanship & Materials',
              description: [
                `Reducing your bulk is in our DNA.`,
                `Our wallets are the slimmest to date and have more space than ever.`,
                `You can seamlessly carry up to 12 cards & 20 Bills at a fraction of the size of a traditional wallet.`,
              ],
              imageName: `air-wallet-promo-1.jpg`,
            },
            {
              title: 'Minimalist, Light and Elegant Design',
              subTitle: 'Less bulk. More storage.',
              description: [
                `The wallet is Slim, sleek and has a modular design that will be the perfect touch for your everyday
            carry essentials since it can perfectly fit discreetly in your front pocket.`,
                `It's time to ditch that bulky
            back breaking wallet!`,
              ],
              imageName: `air-wallet-promo-2.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
            {
              title: 'Anti RFID Technology',
              subTitle: 'Peace of mind.',
              description: [
                `We incorporated the most advanced technology for wallets that you have ever seen.`,
                `The aluminum compartment that we have built inside the wallet secures the data that your cards have, making it impossible for thieves to steal your credit card personal information with a wireless device.`,
              ],
              imageName: `air-wallet-promo-3.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
            {
              title: 'Redefining wallet',
              subTitle: 'Less bulk. More storage.',
              description: [
                `Hand-crafted layers, meticulously sewn to each other.`,
                `Perfect stitching to weave the wallet together.`,
                `As this leather ages, that shine is replaced with a matte finish, and the leather
        becomes softer with use.`,
              ],
              imageName: `air-wallet-promo-4.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
          ].map((props, index) => (
            <ProductFeatureRow key={index} index={index} {...props} />
          ))}
        </section>
        <Footer {...siteConfig} />
      </main>
    </>
  )
}

export default IndexPage
